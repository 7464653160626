
import { createRouter, createWebHashHistory } from 'vue-router'

import About from '@/views/About'
import Onboarding from '@/views/Onboarding'

const routes = [
  {
    meta: {
      title: 'Onboarding'
    },
    path: '/onboarding/:onboardingKey',
    name: 'onboarding',
    component: Onboarding
  },
  {
    meta: {
      title: 'About'
    },
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/',
    redirect: '/onboarding/xxx-xxx-xxx-xxx-xxx'
  }
]

const router = createRouter({
  history: createWebHashHistory('/'),
  routes
})

export default router
