<template>
  <div>
    <!-- Header Section -->
    <header class="header_wrapper">
      <div class="overlay_main"></div>
      <div class="header_container">
        <div class="logo_area">
          <div class="menu_icon">
            <i class="fas fa-bars"></i>
          </div>

          <div class="logo_text">
            <h3>Onboarding for IT Loans</h3>
          </div>
        </div>
      </div>
    </header>

    <!-- Sidebar Section -->
    <section class="sidebar_wrapper">
      <div class="tab_content_wrapper">
        <div class="tab sidebar_tab_main">
          <div class="tab_button_area">
            <div class="img_logo">
              <a href="#">
                <img src="images/header/logo.png" alt="" />
              </a>
            </div>
          </div>
        </div>

        <div class="tab_content_area">
          <loading
            :active="store.state.isLoading"
            is-full-page="true"
            loader="bars"
          >
          </loading>
          <router-view v-if="store.state.user" />
        </div>
      </div>
    </section>
    -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { reactive } from "@vue/reactivity";
import { useStore } from "vuex";
import { watch } from "vue";

export default {
  name: "Home",
  components: {
    Loading,
  },
  setup() {
    const store = useStore();

    watch(
      () => store.state.selectedProfileOption,
      () => {
        if (!store.state.selectedProfileOption) {
          return (store.state.selectedProfileOption = {
            id: store.state.selectedProfileId,
            name: "Select a user",
          });
        }

        if (
          store.state.selectedProfileId === store.state.selectedProfileOption.id
        ) {
          return;
        }

        if (store.state.selectedProfileOption.id === "Create Profile") {
          window._store.dispatch("createProfile");
          return;
        }

        store.state.selectedProfileId = store.state.selectedProfileOption.id;
      },
      { deep: true }
    );

    watch(
      () => store.state.selectedReportOption,
      () => {
        if (!store.state.selectedReportOption) {
          return (store.state.selectedReportOption = {
            id: store.state.selectedReportId,
            label: "Select Credit Report",
          });
        }

        if (
          store.state.selectedReportId === store.state.selectedReportOption.id
        ) {
          return;
        }

        store.state.selectedReportId = store.state.selectedReportOption.id;
      },
      { deep: true }
    );

    watch(
      () => store.state.attackSessionId,
      () => {
        if (store.state.attackSessionId)
          store.dispatch("getSession", store.state.attackSessionId);
      },
      { deep: true }
    );
    return {
      store
    };
  },

  methods: {
    capitalize: (str) => {
      return (str && str.length) >= 1
        ? str.charAt(0).toUpperCase() + str.slice(1)
        : "";
    },
  },
};
</script>

<style scoped>
* >>> .tippy-box[data-theme~="tomato"] {
  background-color: rgba(35, 37, 39, 0.753);
  color: white;

  padding: 5px;
  border-radius: 5px;
}
</style>
